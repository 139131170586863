import React from "react"
function Footer() {
  return (
    <div className='Footer'>
    <a href="https://cahillanelabs.com/">a cahillanelabs project</a> ⋅&nbsp;
    <a href="https://github.com/LinuxFan2718/tictactoe">source code</a>
  </div>
  )
}

export default Footer
